<script>

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { messageService } from '../../../services/message.service';
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { toastUtils } from "../../../utils/toast.utils";
/**
 * Email-inbox component
 */
export default {
  page: {
    title: "Messages reçus",
    meta: [{ name: "description" }]
  },
  components: { Layout, PageHeader, ckeditor: CKEditor.component },
  data() {
    return {
      emailData: [],
      paginatedEmailData: [],
      title: "Méssages reçus",
      items: [
        {
          text: "Programmes",
          href: "/"
        },
        {
          text: "Méssages reçus",
          active: true
        }
      ],
      // page number
      currentPage: 1,
      // default page size
      perPage: 15,

      // start and end index
      startIndex: 1,
      endIndex: 15,
      showModal: false,
      editor: ClassicEditor,
      editorData: "Entrer votre réponse",
      showMessage: false,
      message: null
    };
  },
  computed: {
    rows() {
      return this.emailData.length;
    }
  },
  created() {
    
  },
  mounted() {
    this.getMessage();
    this.startIndex = 0;
    this.endIndex = this.perPage;

    this.paginatedEmailData = this.emailData.slice(
      this.startIndex,
      this.endIndex
    );
  },
  methods: {
    onPageChange() {
      this.startIndex = (this.currentPage - 1) * this.perPage;
      this.endIndex = (this.currentPage - 1) * this.perPage + this.perPage;

      this.paginatedEmailData = this.emailData.slice(
        this.startIndex,
        this.endIndex
      );
    },
    async getMessage() {
      this.emailData = await messageService.get();
    },
    async reponse() {
      let response = new FormData();
      response.append("reponse", this.editorData);
      const resp = await messageService.reponse(this.message.id, response);
      if (resp >= 400) {
        toastUtils.error(this.$bvToast, 'Enregistrement', 'Une erreur s\'est produite. Veuillez réssayer');
      } else {
        toastUtils.success(this.$bvToast, 'Envoi du message', 'Méssage envoyé');

        this.getMessage();
        this.showMessage = false;
        this.showModal = false;
      }
     

    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <!-- Right Sidebar -->
      <div class="col-12">
        <!-- <div class="email-leftbar card">
          <b-button variant="danger" @click="showModal = true">Compose</b-button>
          <div class="mail-list mt-4">
            <a href="javascript: void(0);" class="active">
              <i class="mdi mdi-email-outline mr-2"></i> Inbox
              <span class="ml-1 float-right">(18)</span>
            </a>
            <a href="javascript: void(0);">
              <i class="mdi mdi-star-outline mr-2"></i>Starred
            </a>
            <a href="javascript: void(0);">
              <i class="mdi mdi-diamond-stone mr-2"></i>Important
            </a>
            <a href="javascript: void(0);">
              <i class="mdi mdi-file-outline mr-2"></i>Draft
            </a>
            <a href="javascript: void(0);">
              <i class="mdi mdi-email-check-outline mr-2"></i>Sent Mail
            </a>
            <a href="javascript: void(0);">
              <i class="mdi mdi-trash-can-outline mr-2"></i>Trash
            </a>
          </div>

          <h6 class="mt-4">Labels</h6>

          <div class="mail-list mt-1">
            <a href="javascript: void(0);">
              <span class="mdi mdi-arrow-right-drop-circle text-info float-right"></span>Theme Support
            </a>
            <a href="javascript: void(0);">
              <span class="mdi mdi-arrow-right-drop-circle text-warning float-right"></span>Freelance
            </a>
            <a href="javascript: void(0);">
              <span class="mdi mdi-arrow-right-drop-circle text-primary float-right"></span>Social
            </a>
            <a href="javascript: void(0);">
              <span class="mdi mdi-arrow-right-drop-circle text-danger float-right"></span>Friends
            </a>
            <a href="javascript: void(0);">
              <span class="mdi mdi-arrow-right-drop-circle text-success float-right"></span>Family
            </a>
          </div>

          <h6 class="mt-4">Chat</h6>

          <div class="mt-2">
            <a href="javascript: void(0);" class="media">
              <img
                class="d-flex mr-3 rounded-circle"
                src="@/assets/images/users/avatar-2.jpg"
                alt="Generic placeholder image"
                height="36"
              />
              <div class="media-body chat-user-box">
                <p class="user-title m-0">Scott Median</p>
                <p class="text-muted">Hello</p>
              </div>
            </a>

            <a href="javascript: void(0);" class="media">
              <img
                class="d-flex mr-3 rounded-circle"
                src="@/assets/images/users/avatar-3.jpg"
                alt="Generic placeholder image"
                height="36"
              />
              <div class="media-body chat-user-box">
                <p class="user-title m-0">Julian Rosa</p>
                <p class="text-muted">What about our next..</p>
              </div>
            </a>

            <a href="javascript: void(0);" class="media">
              <img
                class="d-flex mr-3 rounded-circle"
                src="@/assets/images/users/avatar-4.jpg"
                alt="Generic placeholder image"
                height="36"
              />
              <div class="media-body chat-user-box">
                <p class="user-title m-0">David Medina</p>
                <p class="text-muted">Yeah everything is fine</p>
              </div>
            </a>

            <a href="javascript: void(0);" class="media">
              <img
                class="d-flex mr-3 rounded-circle"
                src="@/assets/images/users/avatar-6.jpg"
                alt="Generic placeholder image"
                height="36"
              />
              <div class="media-body chat-user-box">
                <p class="user-title m-0">Jay Baker</p>
                <p class="text-muted">Wow that's great</p>
              </div>
            </a>
          </div>
        </div> -->
          <div class="card" v-if="!showMessage">
            <!-- <div class="btn-toolbar p-3">
              <Toolbar />
            </div> -->
            <div class="mt-3">
              <ul class="message-list">
                <li
                @click="showMessage = true; message = email"
                style="cursor: pointer;"
                  v-for="(email,index) in emailData"
                  :key="index"
                  :class="{ 'unread': email.reponse == null }"
                >
                  <div class="col-mail col-mail-1">
                    <div class="checkbox-wrapper-mail">
                      <input :disabled="true" :checked="email.reponse != null" :id="`chk-${index}`" type="checkbox" />
                      <label :for="`chk-${index}`"></label>
                    </div>
                    <a class="title">{{email.sender_mail}}</a>
                  </div>
                  <div class="col-mail col-mail-2">
                    <a class="subject">{{email.sujet}}</a>
                    <div class="date">{{email.created | messageListDate }}</div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div v-if="!showMessage" class="row justify-content-md-between align-items-md-center">
            <div class="col-xl-7">Showing {{startIndex}} - {{endIndex}} of {{rows}}</div>
            <!-- end col-->
            <div class="col-xl-5">
              <div class="text-md-right float-xl-right mt-2 pagination-rounded">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                  @input="onPageChange"
                ></b-pagination>
              </div>
            </div>
            <!-- end col-->
          </div>
        </div>


        <div v-if="showMessage && message != null" class="card" style="width: 100%;">
          <div class="btn-toolbar p-3" role="toolbar">
            Message reçu le {{ message.created | defaultFormatDate }}
          </div>

          <div class="card-body">
            <div class="media mb-4">
              <img
                class="d-flex mr-3 rounded-circle avatar-sm"
                src="@/assets/logo.png"
                alt="Generic placeholder image"
              />
              <div class="media-body">
                <h5 class="font-size-14 my-1">{{message.sender_mail}}</h5>
                <small class="text-muted">Radio Shalomat</small>
              </div>
            </div>

            <h4 class="mt-0 font-size-16">Sujet: {{ message.sujet }}</h4>
            <br>
            <p v-html="message.contenu"  ></p>
            <hr />

            <div v-if="message.reponse != null">
              <h4 class="mt-0 font-size-16">Réponse:</h4>
              <br>
              <p v-html="message.reponse"  ></p>
              <hr />
            </div>

            <a v-if="message.reponse == null" @click="showModal = true" href="javascript: void(0);" class="btn btn-primary waves-effect mt-4">
              <i class="mdi mdi-reply"></i> repondre
            </a>
            <a href="javascript: void(0);" @click="showMessage = false; message = null" class="btn btn-secondary waves-effect mt-4 ml-2">
              <i class="mdi mdi-close"></i> Fermer
            </a>
          </div>
        </div>

        <b-modal v-if="message != null" v-model="showModal" title="Nouveau méssage" centered>
        <form>
          <div class="form-group">
            <input type="email" disabled v-model="message.sender_mail" class="form-control" placeholder="A" />
          </div>

          <!-- <div class="form-group">
            <input type="text" class="form-control" placeholder="Objet" />
          </div> -->
          <div class="form-group">
            <ckeditor v-model="editorData" :editor="editor"></ckeditor>
          </div>
        </form>
        <template v-slot:modal-footer>
          <b-button variant="secondary" @click="showModal = false">Fermer</b-button>
          <b-button @click="reponse()" variant="primary">
            Envoyer
            <i class="fab fa-telegram-plane ml-1"></i>
          </b-button>
        </template>
      </b-modal>
      
      </div>

    
  </Layout>
</template>