import errorHandler from './handler/erroHandler';
import axiosInstance from './manager/manager.service';

export const messageService = {
    reponse,
    update,
    get,
    del
};

async function reponse(id, data) {

    try {
        let ret = await axiosInstance.put(`/messages/response/${id}`, data);

        return ret.data;
    } catch (error) {
        return errorHandler(error);
    }
}

async function get() {

    try {
        let ret = await axiosInstance.get(`/messages`);

        return ret.data;
    } catch (error) {
        console.log(error);
        return [];
    }

}

async function update(data, id) {
    try {
        let ret = await axiosInstance.put(`/messages/${id}`, data);
        return ret.data;
    } catch (error) {
        console.log(error);
        return errorHandler(error);
    }
}


async function del(id) {
    try {
        let ret = await axiosInstance.delete(`/messages/${id}`);
        return ret.data;
    } catch (error) {
        console.log(error);
        return errorHandler(error);
    }
}


